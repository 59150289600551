@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap");

.navbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.75),
    2px 2px 4px rgba(102, 58, 163, 0.25); */
    /* 2px 2px 4px rgba(253, 101, 45, 0.25) */
  box-shadow: 2px 2px 4px rgba(253, 101, 45, 0.25);
  border-radius: 10px;
  padding: 1rem;
  padding-bottom: 8rem;
  padding-right :0;
  margin-bottom: 2rem;
}

.navItems {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.navItems img {
  width: 10%;
}
.navItems h1 {
  width: 70%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  color: #444444;
  margin-left: 1rem;
  margin-bottom: 0;
}

.isSelected{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2.5rem;
    text-decoration-line: none;

    color:orangered;

}

.isNotSelected{
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 2.5rem;

color: #444444;
}