.mobile-CardsWrapper {
    margin: 1rem 0 1rem 0;
  }
  
  .mobile-event-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: #444444;
  }
  
  .mobile-event-date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.4rem;
    /* identical to box height */
  
    color: #666666;
  }
  
  .mobile-event-resources {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.3rem;
    /* identical to box height */
  
    text-decoration-line: underline;
  
    color: #663aa3;
  }
  .mobile-event-unregister {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.3rem;
    /* identical to box height */
  
    text-align: right;
    text-decoration-line: underline;
  
    color: #ff5959;
  }
  .mobile-EventCards {
    display: grid;
    grid-template-columns:  100%;
    column-gap: 2%;
    row-gap: 4%;
  }
  
  .mobile-EventType {
    background: #ffffff;
    box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
    border-radius: 2.93684px;
    padding: 1.5rem;
   
  }
  
  .mobile-event-links {
    display: flex;
    justify-content: space-between;
  }
  