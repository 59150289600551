.single-participant-form{
    box-shadow: 2px 2px 4px rgba(102, 58, 163, 0.25);
    border-radius: 8px;
    padding : 0.5rem
}

.input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.input-form-field{
    
    margin : 1rem
}

.submit-button-row button{
    background: orangered;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
}

.my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}