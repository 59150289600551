.single-member-component-container{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    align-items: center;
    padding : 0.6rem;

    color: #663AA3;

    background: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
    border-radius: 4px;
    margin : 0.5rem 0 0.5rem 0
}