.wrapper {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.75),
      2px 2px 4px rgba(102, 58, 163, 0.25);
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */
  
    border-radius: 10px;
  }
  
  .heading {
    height: 20%;
    width: 90%;
    margin: auto;
    padding-top: 0.5rem;
  }
  
  .indexupdates {
    height: 80%;
    /* overflow-y: hidden; */
  }
  .update-list-component {
    overflow-y: scroll;
    height: 50vh;
  }
  
  .component {
    padding-bottom: 2rem;
    width: 80%;
    margin: 0.1rem;
  }
  
  .heading {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
    padding-bottom: 1rem;
    color: #222222;
  }
  .date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #666666;
  }
  
  .line {
    border: 1px solid #888888;
    width: 100%;
  }
  
  .updates {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  
  .profile {
    display: flex;
  }
  
  .profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .time {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    padding-right: 1.5rem;
    color: #aaaaaa;
  }
  
  .profilePhoto {
    padding: 0.2rem;
  }
  
  .taskPerson {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1rem;
  
    color: #222222;
    margin:0.2rem
  }
  
  .taskInfo {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.0rem;
  
    color: #666666;
    margin:0.2rem
  }

  .update-list-component::-webkit-scrollbar {
    width: 0.2rem;
  }

  .update-list-component::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

  .update-list-component::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }