.updates-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.updates-component{
    height: 85%;
}
