.header-top {
    position: relative;

}

.header-top {
    position: relative;
}

.header-img-wrapper img {
    /* display: grid;
    grid-template-columns: 25vw 17vw 21vw 13.8vw ;
    grid-template-rows:23vh 2vh 14vh 14vh 25vh ;
    grid-gap: 0px; */
    height: 100vh;
    width: 110vw;
    /* change */
}

.ss-header-img1 {
    height: 25vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

.ss-header-img2 {
    height: 23vh;
    width: 17vw;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img3 {
    height: 23vh;
    width: 21vw;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img4 {
    height: 23vh;
    width: 14vw;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img5 {
    height: 39vh;
    width: 22vw;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
}

.ss-header-img6 {
    height: 28vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;
}

.ss-header-img7 {
    height: 55vh;
    width: 52vw;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 6;

}

.ss-header-img8 {
    height: 39vh;
    width: 22vw;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 6;
}

.ss-header-img9 {
    height: 25vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
}

.logo-heading {
    position: absolute;
    top: 25vh;
    left: 35vw;
    /* border: 3px solid red; */
    height: 200px;
    width: 400px;
    /* display:flex; */
}

.logo-img img {
    height: 170px;
    width: 170px;
    border-radius: 90%;
}



.text .para1 {
    color: #86E7D3;
    font-family: "" Mulish"";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 89%;
    /* 63.36px */
    padding: 10px;

}

.text .para2 {
    color: #DDC38B;
    color: #DDC38B;
    font-family: Mulish;
    font-size: 18.911px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#para3 {
    color: #DDC38B;
    font-size: 20px;
    font-weight: 700;
    font-family: "" Mulish"";
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.h_tickets{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width:1100px) {
    .logo-heading {
        left: 33vw;
    }

    .logo-img img {
        height: 160px;
        width: 160px;
    }

    .text .para1 {
        font-size: 47px;
    }

    .text .para2 {
        font-size: 22px;
    }
}

@media only screen and (max-width:1000px) {
    .logo-heading {
        left: 31.5vw;
    }

    .logo-img img {
        height: 150px;
        width: 150px;
    }

    .text .para1 {
        font-size: 46px;
    }

    .text .para2 {
        font-size: 21px;
    }
}

@media only screen and (max-width:900px) {
    .logo-heading {
        left: 30vw;
    }

    .logo-img img {
        height: 140px;
        width: 140px;
    }

    .text .para1 {
        font-size: 42px;
    }

    .text .para2 {
        font-size: 20px;
    }
}

@media only screen and (max-width:800px) {
    .logo-heading {
        left: 28.5vw;
    }

    .logo-img img {
        height: 130px;
        width: 130px;
    }

    .text .para1 {
        font-size: 40px;
    }

    .text .para2 {
        font-size: 19px;
    }
}

@media only screen and (max-width:700px) {
    .logo-heading {
        left: 25vw;
        width: 300px;
    }

    .logo-img img {
        height: 120px;
        width: 120px;
    }

    .text .para1 {
        font-size: 37px;
    }

    .text .para2 {
        font-size: 18px;
    }
}

@media only screen and (max-width:600px) {
    .logo-heading {
        left: 24vw;
    }

    .logo-img img {
        height: 110px;
        width: 110px;
    }
}

@media only screen and (max-width:570px) {
    .logo-heading {
        left: 22vw;
    }
}

@media only screen and (max-width:550px) {
    .logo-heading {
        left: 21vw;
    }
}

@media only screen and (max-width:530px) {
    .logo-heading {
        left: 20vw;
    }
}

@media only screen and (max-width:500px) {

    .header-top {
        display: none;
    }
}