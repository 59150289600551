@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap");



.leaderboard h1 {
  width: 90%;
  margin: auto;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.3rem;
  color: #222222;
}

.rankers-2{
    column-count: 2;
    row-gap: 0.1rem;
    column-gap: 10rem;
    column-rule: 4px solid #663aa36c;
}

.rankers-1{
  column-count: 1;
  row-gap: 0.1rem;
  column-gap: 10rem;
  column-rule: 4px solid #663aa36c;
}



.ranker {
    width: 100%;
  }