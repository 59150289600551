.list-component-div{
    position : absolute;
    top : 2rem;
    z-index : 1000;
    width : 100%;
    height : 15rem;
    overflow-y: scroll;
}

.list-component-div::-webkit-scrollbar {
    width: 0.2rem;
  }

  .list-component-div::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

  .list-component-div::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }


.select-tasks-dropdown{
    background: rgba(142, 142, 147, 0.12);
    border-radius: 5px;
}

.select-tasks-header{
    color : #444444;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    margin : 0.4rem;
    display: flex;
    align-items: center;
}

.select-tasks-dropdown{
    width : 60%;
    background: rgba(142, 142, 147, 0.12);
    border-radius: 5px;
    padding : 0.4rem;
    margin : 0.4rem
}

.select-task-name{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    /* identical to box height */
    padding:0;
    display: flex;
    align-items: center;

    color: #666666;

}

.select-tasks-dropdown{
    position :relative
}

.dropdown-list{
    position: absolute;
    top : 2.5rem;
    z-index : 1000
}

.select-task-list-component{
    padding-top: 0.3rem;
}

.select-tasks-single-component{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1rem;
    /* identical to box height */
    padding:0;
    display: flex;
    align-items: center;

    color: #666666;
}

.select-tasks-single-component:hover {
    background: rgba(142, 142, 147, 0.3);;
  }
