.payment-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.payment-component{
    height: 85%;
}


.payment-button a{
    background: orangered;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    padding : 1rem;
    margin : 1rem 0 1rem 0;
    color : #ffffff
}