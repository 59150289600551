.deadlines-header{
    height : 7%;
    width : 100%;
    margin-bottom : 1rem
}



.deadlines-component{
    height: 85%;
    overflow-y: scroll;
}

.deadlines-component::-webkit-scrollbar {
    width: 0.1rem;
  }

.deadlines-component::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

.deadlines-component::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }
