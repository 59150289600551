.tabs-container{
    height : 100%,
    
}

.selected-tab{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1rem;
    text-decoration-line: none;
    color: orangered;
    border-bottom: 0.1rem solid orangered;
    padding-left: 2.5em;
    padding-bottom: 0.9em;
}

.not-selected-tab{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #666666;
}