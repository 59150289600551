.leaderboard-header{
    height : 10%;
    width : 100%;
    margin-bottom : 1rem
}



.leaderboard-component{
    height: 85%;
}
