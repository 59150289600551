.CardsWrapper {
  margin: 1em;
}

.event-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #444444;
}

.event-date {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.4rem;
  /* identical to box height */

  color: #666666;
}

.event-resources {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3rem;
  /* identical to box height */

  text-decoration-line: underline;

  color: #663aa3;
}
.event-unregister {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3rem;
  /* identical to box height */

  text-align: right;
  text-decoration-line: underline;

  color: #ff5959;
}
.EventCards {
  display: grid;
  grid-template-columns:  23% 23% 23% 23%;
  column-gap: 2%;
  row-gap: 4%;
}

.EventType {
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
  border-radius: 2.93684px;
  padding: 1.5rem;
 
}

.event-links {
  display: flex;
  justify-content: space-between;
}

