.choose-event-text{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1rem;
  display: flex;
  align-items: center;

  color: #444444;
  margin : 0.5rem 0 0.5rem 0
}

