@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap");

.rankerCard {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0;
  justify-content: space-between;

}

.profile{
  margin-right : 0.5rem;
}


.rankerName {
  width: 65%;
}

.points {
  width: 20%;
}

.rankerName h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
 
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #444444;
  margin: 0;
}

.rankerName h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 0.9rem;
  /* identical to box height */
  color: #663aa3;
  margin: 0;
}

.points {
  margin-left: auto;
}

.points h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  /* identical to box height */
  text-align: right;
  color: #444444;
  margin: 0;
}

.verticalLine {
  position: absolute;
  height: 58%;
  left: 51%;
  opacity: 0.3;
  border-radius: 16px;
  border: 4px solid #663aa3;
}