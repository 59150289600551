
.points{
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: right;

    color: #663AA3;

}

.current-points{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.75rem;
    color: #444444;
    text-align: right;
}

.profile-header{
    height : 15%;
    width : 100%
}

.profile-header-container{
    border-bottom : 0.1rem solid #8561B5;
    
}

.profile-component{
    height: 85%;
}

