/* .home-top-content-wrapper{
    margin:0px;
    padding: 0px;
} */

.home-top-content-wrapper{
    
}
.header-bottom {
    /* background-image: url("../Image/image\ 38.png"); */
    /* position: relative; */
    height: auto;
    text-align: center;
    padding: 14vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    /* margin-top: 0px;
    margin: 0; */

}
.backimg-head{
    position: absolute;
    /* left: 0px;
    top: 0px; */
    z-index: -10;
    width:110vw;
    height: 126vh;
    padding: 10px 0;

    /* filter: brightness(50%); */
  }
.header-bottom-heading {
    font-family: ""Mulish"";
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 99%;
    /* 63.36px */
    background: linear-gradient(to bottom, #652222 40%, #CB4545 100%);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.header-bottom-para1 {
    text-align: left;
    font-family: ""Mulish"";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 70%;
    /* 43.2px */
    /* background: linear-gradient(184deg, #FFF 3.29%, #5DC1C2 176.52%); */
    background-color: #43766C;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 30px 0px;
    /* display: flex;
    flex-direction: column; */
}
.header-bottom-line2{
    font-size: 25px;
}
.header-bottom-para3 {
    color: #652222;
    text-align: left;
    font-family: ""Mulish"";
    font-size: 20px;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #C1A76F;
    border-radius: 30px;
    padding: 2rem;
    width: 73vw;
    background-color: rgba(193, 167, 111, 0.6)
}

.header-bottom-button {
    /* background-color: black; */
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    /* height: 200vh; */
}

.header-bottom-button button {
    display: inline-flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #5AB9BE;
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: transparent;

}

#btn-1 {
    margin-right: 20px;
    border-radius: 5rem;
    /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
    background: linear-gradient(90deg, #00d084 0%, #008758 100%);
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
}
#btn-1:hover{
    border: 2px solid #8FEFE9;
    box-shadow: 4px 4px 16px 2px rgba(153, 238, 232, 0.5);
}
.home-logo-display{
    font-weight: 600px;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    display:none;
}
.nss{
    padding-top: 2rem;
}

@media only screen and (max-width:900px){
    .home-logo-display{
        display: flex;
    }
    /* .header-bottom-para3{
        width: 40rem;
    } */

    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:1230px){
    .backimg-head{
        height: 163vh;
    }
}
/* @media only screen and (max-width:1020){
    .backimg-head{
        height: 200vh;
    }
} */

@media only screen and (max-width:1090px){
    .header-bottom-heading{
        font-size: 55px;
    }
    /* .header-bottom-para3{
        width: 50rem;
    } */
    .backimg-head{
        height: 163vh;
    }
}
@media only screen and (max-width:1020){
    /* .backimg-head{
        height: 190vh;
    } */
    .home-top-content-wrapper{
        height: 59rem;
    }
}
@media only screen and (max-width:930px){
    .header-bottom-heading{
        font-size: 50px;
    }
    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:940px){
    .header-bottom-heading{
        font-size: 45px;
        
    }
    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:820px){
    .home-top-content-wrapper{
        height: 67rem;
    }
}
@media only screen and (max-width:760px){
    .header-bottom{
        padding: 5vh 12vw;
    }
    .backimg-head{
        height: 204vh;
    }
}



@media only screen and (max-width:720px){
    .header-bottom-heading{
        font-size: 40px;
    }
    .backimg-head{
        height: 230vh;
    }
}
@media only screen and (max-width:700px){
    .home-top-content-wrapper{
        height: 63rem;
    }
}
@media only screen and (max-width:630px){
    .home-top-content-wrapper{
        height: 55rem;
    }
}


@media only screen and (max-width:640px){
    .home-top-content-wrapper{
        height: 55rem;
    }
    .header-bottom-heading{
        font-size: 35px;
    }
    .header-bottom-para1{
        font-size: 22px;
    }
    .header-bottom-line2{
        font-size:17px;
    }
    .header-bottom-para3{
        font-size: 17px;
    }
    .backimg-head{
        height: 197vh;
    }
}
@media only screen and (max-width:560px){
    
    .home-top-content-wrapper{
        height: 50rem;
    }
    .backimg-head{
        height: 178vh;
    }
    .header-bottom-heading{
        font-size: 30px;
    }
    .header-bottom-line2{
        font-size: 18px;
    }
    .header-bottom-para3{
        font-size:14px;
        width: 24rem;
        
    }
    .header-bottom-para3{
        font-size: 16px;
        margin-top: 5vh;
    }
    .header-bottom-para1{
display: none;
    }
}

@media only screen and (max-width:400px){
    .backimg-head{
        /* width:  32rem; */
        width: 100%;
    }
    .header-bottom{
        display: flex;
        flex-direction: column;
        /* padding-left: 7rem; */
        /* width: 32rem; */
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    /* html{
        display: flex;
        justify-content: center;
    } */
    .home-logo-display{
        /* display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center; */
        /* margin-left: 20px; */
        /* width: 32rem; */
        width: 100%;
    }
    .header-bottom-button{
       /* margin: 0 auto; */
        padding-right: 105px;
        
    }
    .header-bottom-para3{
        width: 90vw;
    }
    #btn-1{
        margin: 0;
        scale: 0.8;
        align-items: center;
    }
    
}

