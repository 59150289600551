.input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.input-form-field{
    margin : 1rem
}

.my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}