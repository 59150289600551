@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap");

.itemCard {
  display: flex;
  
  justify-content: space-between;
  padding: 0.6rem 0;
}

.dateBlock {
  display: inline-flex;
  padding: 0.2rem;
  flex-direction: column;
  justify-content: center;
  width: 12%;
  align-items: center;
  /* height: 2px; */
  background: #f5e0d1;
  box-shadow: inset -1px -1px 4px #ffe8d7,
    inset 1px 1px 4px rgb(158 143 132 / 50%);
  border-radius: 5px;
}

.dateBlock h1 {
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #663aa3;
  margin: 0;
}
.dateBlock h2 {
  display: inline-block;
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1rem;
  text-align: center;
  color: #663aa3;
}

.tasks {
  display: flex;
  flex-direction: column;
  align-self: center;
  /* j-content: space-between; */
  /* margin-left: 2vw; */
  width: 83%;
}

.tasks h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2rem;
  /* identical to box height */
  color: #444444;
}

.tasks p {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1rem;
  /* identical to box height */
  color: #aaaaaa;
}