.events-header{
    height: 9%;
    width : 100%;
    margin-bottom : 1rem
}



.events-component{
    height: 85%;
}

