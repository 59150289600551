.body-container{
    background-image:url("../../../assets/images/bg-signup.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size:100% 45%;
    background-attachment: fixed;
    width : 100%;
    height : 100%;
    padding: 0rem;
  }
  
  
