body{
    background: #080808;
z-index: -10000;
}
.Contact-container {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-content: space-evenly;
    background: #080808;
    /* padding: 5vh 2vw 5vh 2vw; */
    
}

.footerCol1 {
    display: flex;
    flex-direction: column;
    width: 24vw;
    height: 40vh;
    align-items: center;
    padding: 3%;
    gap: 50px;
}
.footerLogo {
    display: flex;
    flex-direction: row;
    width: 94%;
    height: 20%;
    padding-right: 8%;
    gap: 1%;
}

.logoImg {
    width: 40%;
    height: 100%;
   
}
.logoContaint {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
    height: 100%;
    
}
.logo2{
    width: 80%;
}

.NSS {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 19.381px;
    font-style: normal;
    font-weight: 600;
    line-height: 99%;
    height: 90%;
    
}

.date-box {
    font-family: ""Mulish"";
    font-size: 7.268px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 10%;
}


.footerText {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 94%;
    height: 35%;
}

.contactusBtn {
    font-family: ""Mulish""; 
    font-size: 16px;
    font-style: normal;
    margin-top: 2vh;
    font-weight: 700;
    line-height: normal;
    width: 94%;
    height: 15%;
    
}
.btn-footer {
    /* margin-right: 20px; */
    border-radius: 20px;
    color: #FFF;

    /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
    background: linear-gradient(95.72deg, #10D7B0 2.01%, #08856C 97.01%);

    padding:8px 10px;
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
}
.btn-footer:hover{
    border: 2px solid #72e0d7;
    box-shadow: 4px 4px 16px 2px rgba(153, 238, 232, 0.5);
}

.footerText {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 94%;
    height: 35%;
}

.contactusBtn {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 94%;
    height: 15%;
}
.footerCol2 {
    display: flex;
    flex-direction: row;
    color: #FFF;
    text-decoration: none;
    width: 35vw;
    height: 50vh;
    gap: 8rem;
    
    padding: 6vh 6vw 6vh 6vw;
    margin-top: 4rem;
}
.col1 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-left: 4vw; */
    /* text-align: center; */
    gap: 10px;
    color: #FFF;
    text-decoration: none;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 23vw;
    height: 38vh;
}

.col1 a{
    text-decoration: none;
    color: #FFF;
}
.col-conn {
    display: inline-flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin-left: 1vw;
    text-align: center;
    margin-top: 2vh;
    gap: 16px;
    color: #FFF;
    text-decoration: none;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 23vw;
    height: 38vh;
}
.col-conn a{
    text-decoration: none;
    color: #FFF;
}
.footerCol3 {
    display: flex;
    flex-direction: row;
    width: 30vw;
    height: 50vh;
    align-items: center;
    justify-content: center;
    padding: 10vh 3vw 10vh 3vw;
    margin-left: 3vw;
    color: #FFF;
}
.icon-box{
width:22vw;
height:20;
gap: 10%;
margin-top: 3rem;
margin-left: 5rem;
display: flex;
 align-items: center;
    justify-content: center;
}
.icon{
    width: 10%;
    height: 10%;
    
}
.fabIcon{
    color: #E7DDB2;
}


.col1 a{
    color: #E7DDB2;
}
.col1 h4{
    color: #86E7D3;

}

.footerText p{
    color: #E7DDB2;
}
.logoContaint p{
    color: #86E7D3;
}
.date-box p{
    color: #E7DDB2;
}