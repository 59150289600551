body {
    background: #fafafa;
  }
  .th-registration {
    /* background-position: bottom; */
    /* background-repeat: no-repeat; */
    /* background-size: contain; */
    height: 100vh;
    /* overflow: hidden; */
  }
  .th-login {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    /* overflow: hidden; */
  }
.login{
  width: 100%;
}
  #nsslogin1{
    height: auto;
    width:100%;
  }

  .th-registration-btn,
  .th-registration-btn-2 {
    display: inline-flex;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  }
  .th-registration-btn-2:hover{
    cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
  }
  .th-registration-btn {
    padding: 10px 42px;
  }

  .th-registration-btn-2:focus {
    box-shadow: none !important;
  }
  .th-registration-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 36.5px;
    color: #000000;
  }
  .th-registration-text span {
    color: #663aa3 !important;
  }
  .th-register-right-we {
    display: flex;
    flex-direction: column;
    font-family: Raleway;
  }
  .th-register-right-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Raleway;
    flex-basis: 50%;
    
  }
  
  .th-right-text-1 {
    font-size: 33px;
    font-weight: bold;
  }
  .th-right-text-2 {
    font-size: 18px;
    font-family: Inter;
    color: #aaaaaa;
    text-align: left;
    margin-top: 4%;
    margin-left: 2%;
  }
  
  .th-right-link {
    color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }

  #regtxt1{
    display:flex;
  }

#black1{
  color:black;
  padding-right:10px;
}

  #regnow{
    margin-top:16px;
  }

  .logintxthead{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .star1{
    color:#FD652D;
  }

  .rows{
    margin-top: 5%;
    display: flex;
  }

  #forgotpasslog{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .registration-form {
    padding: 3rem;
    text-align: center;
 
  }
  #logintxthead1{
    /* position: absolute; */
    /* width: 54px; */
    /* height: 24px; */

    font-family: 'Lato';
    font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #22495A;
  margin-bottom:2%;
  }
  #regtxthead1{
    color: #000;
font-family: Mulish;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 5%;
  }

#namephone{
  display:flex;
  flex-direction: row;
  margin-top:2%;
}



  #mainlogdiv{
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
 
  }  

#forgotpass{
  margin-top:3%;
} 



.star1{
  text-align:left;
}

#leftregdiv{
  
  width:50%;
} 

#regbtn{
  border-radius: 8px;
background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%);
box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
display: inline-flex;
padding: 8px 22px;
justify-content: center;
align-items: center;
gap: 10px;
color: #FFF;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 15%;
height: 6%;
}
#regbtn:hover{
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
}
.rbtn{
  margin: 4%;
  display: flex;
  justify-content: center;
}
#regtxt{
  margin-top:4%;
  display: flex;
  justify-content: center;
  color: #000;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

  
  /* @media only screen and (max-width: 1000px) {
    #mainlogdiv{
      display: flex;
      flex-direction: column;
    }


  } */
  
  .th-registration-overlay {
    position: absolute;
    left: 100%;
    bottom: 10%;
    transform: translate(-75%, -50%);
    width: 15%;
    z-index: 100;
  }
  
  .th-form-control:focus {
    box-shadow: none !important;
    border: none !important;
  }
  
  .th-form-group {
    display: flex;
    flex-basis: 85%;
    margin: 2.5%;
  }
  .rhide{
    margin-top: 3%;
    width: 6%;
  }
  .rhideimg{
    width: 100%;
  }



  #regimg{
    width:100%;
    
  }

  #rightregdiv{
    width: 50%;
  }

  .th-form-group label {
    /* transition: all 0.3s ease; */
    margin-bottom: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #8e8e93;
  }
  
  .th-form-control {
    /* transition: all 0.3s ease !important; */
    /* background: #f3ebff; */
    border-radius: 2.909px;
background: #D6EBE7;
box-shadow: 0.727px 0.727px 2.909px 0px rgba(0, 0, 0, 0.25);
    width: 85%;
    padding: 2%;
    margin-left: 8%;
    padding-left: 10%;
  }
  .th-form-control1 {
    border-radius: 2.909px;
    background: #D6EBE7;
    box-shadow: 0.727px 0.727px 2.909px 0px rgba(0, 0, 0, 0.25);
  }
  
/* #passwordinput{
  margin-bottom:16px;
  width:61.45%;
} */

.regmaindiv{
  display:flex;
  
  
}

.colmarg{
  flex-basis: 50%;
}
#hidepass{
  display: flex;
  flex-direction: row;
}
  .th-form-control:focus {
    outline: none !important;
    border-color: none !important;
    /* background: #d5bbf8 !important; */
    /* color: #663aa3 !important; */
    box-shadow: none !important;
    border: none !important;
  }

  @media only screen and (min-width: 990px) {
    .regline{
      width:100% !important
    }
  }

  @media only screen and (max-width: 990px) {

    #nsslogin1{
      display:none;
    }

    

    .colmarg{
      padding:0rem;
      margin: auto;
    }

    .registration-form{
      margin:auto;
    }

    .th-registration-text {
      font-size: 26px;
      line-height: 150%;
    }
.th-form-control1 {
display: inline-block;
}
    #rightregdiv{
    margin:auto;
    margin-bottom: 2%;
   }

   #loginlinehead{
    width:61vw;
   }

    #regimg{
      margin:0px;
    }
  
    #leftregdiv{
      height:0px;
      width:0px;
    }

    #namephone{
      flex-direction:column;
    }

    #mainlogdiv{
      display: flex;
      flex-direction: column;
    }

    /* #loginlinehead{
      width:100%;
    } */

    /* #nsslogin1{
      display:none;
    } */

    #regimg{
      display:none;
    }



    #newacctxt{
      display: none;
    }

    #newacctxt1{
      display: none;
    }


    #logcont{
      display:flex;
    }
    
  }
.mainbox{
  display: flex;
  flex-direction: row;
}
.imglogin{
  flex-basis: 50%;
  position: relative;
}
.logimg{
  width: 100%;
  height: auto;
}
.logcontent{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}
.loginhead{
  margin-top: 5%;
}
.loginhead2{
  margin-top: 3%;
}
.userinp{
  position: relative;
  margin-top: 10%;
}
.userimg{
  position: absolute;
  left: 12%;
  top: 18%;
}
.passinp{
  position: relative;
  display: flex;
  margin-top: 4%;
}
.passimg{
  position: absolute;
  left: 12%;
  top: 18%;
}
.hidimg{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5%;
}
.hideimage:hover{
  cursor: pointer;
}
.loghead{
  color: #000;
font-family: Mulish;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.loghead2{
  color: #000;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.logbtn{
  margin-top: 4%;
}
.loginbtn{
  display: inline-flex;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
.loginbtn:hover{
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
}
.forgetpass{
  margin-top: 5%;
}
.forpass{
  color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
input::placeholder{
  
  color: rgba(0, 0, 0, 0.50);
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.account{
  margin-top: 2%;
  color: #000;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.signaccount{
  color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media (max-width:990px){
  .imglogin{
    display: none;
    flex-basis: 0%;
  }
  .logcontent{
    flex-basis: 100%;
  }
  .th-form-control{
    width:100%;
    margin-left: 0px;
  }
  .userimg{
    left: 3%;
  }
  .passimg{
    left: 3%;
  }
  .passinp{
    width: 108%;
  }
  #rightregdiv{
    width: 100%;
  }
  #regbtn{
    width: 100px;
  }
  .rhide{
    width: 30px;
    margin-top: 10px;
  }
}
@media (min-width: 200px){
  .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
  }
  }
#sendbtn{
  position: relative;
  left: 15%;
}
@media (min-width: 575px){
.col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}
}

/* 
  @media only screen and (max-width: 776px) {
      .colmarg{
        padding-left: 0rem;
        padding-right: 0rem;
      }
      .th-form-control{
        width:100%;
      }

      #logintxthead1{
        display: flex;
        justify-content: center;
        margin-bottom:2%;
      }
      #forgotpass{
        text-align:left;
        margin-bottom:5%;
        margin-left:0px;
      }
      #loginbtn{
        display:flex;
        align-items: center;
        justify-content: center;
      }

      #mainlogdiv{
        display: flex;
        flex-direction: column;
      }
  
  }
  
  @media only screen and (max-width: 767px) {
    .th-register-col-2-web {
      display: none !important;
    }
  
    .th-registration-btn-1 {
      text-align: center !important;
      display: flex;
      justify-content: center;
    }
    .th-form-control{
      display: inline-block;
    }
  }
  @media only screen and (min-width: 768px) {
    .th-register-col-2-mobile {
      display: none !important;
    }
    .th-form-control{
      display: inline-block;
    }
  
  } */