@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100&family=Raleway:wght@100&display=swap);
/* header */
/* header */
.th-header {
  margin-bottom: 0px;
  z-index: 1000;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  padding: 10px 0;
  transition: all ease 0.5s;
  background: #101010;
  border-bottom: 3px solid gray;
  margin-bottom: 0px;

  transition: top 0.3s ease;
}

.Header {
  transition: top 0.3s ease;
}

.th-header-bg {
  background: #101010;
  box-shadow: 0 0 10px 0 rgb(50 50 50 / 50%);
  /* padding: 12px 24px;
  transition: all ease 0.5s; */
  margin-bottom: 0px;
}

.mob-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.th-header-logo,
.th-nss-logo {
  width: 70px;
  border-radius: 50%;

}

.navmar {
  margin-left: 2rem !important;
}

.th-nss {
  font-family: "" Mulish"";
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px;
  color: White;
}

.th-header-new {
  display: inline-block;
  width: 24px;
  position: absolute;
  left: 144px;
  line-height: 20px;
  font-size: 22px;
}

.summit_date {
  color: #8FEFE9;
  position: absolute;
  left: 130px;
  top: 66px;
}

.th-clg-name {
  font-family: "" Mulish"";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #181818;
}

.b1234 {
  box-sizing: border-box;
  color: #ffffff;
  font-family: "" Mulish"";

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  grid-gap: 8px;
  gap: 8px;



  /* CP/Orange */

  background: linear-gradient(94deg, #5AB8BD 22.96%, #35A89B 98.15%);
  ;
  /* CP/Orange */

  border: 1px solid #5AB9BE;
  /* drop-shadow/button-primary */

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
}

.th-nss1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #181818;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .th-header-logo {
    width: 60px;
  }

  .th-nss,
  .th-clg-name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .th-header {
    padding: 16px 0;
  }

  .th-header-bg {
    padding: 8px 0;
  }

  .th-header-logo {
    width: 55px;
  }

  .th-nss,
  .th-clg-name {
    font-size: 17px;
  }
}

.navbar1 {
  position: "absolute";
  margin-left: 19vw;
}

@media only screen and (max-width: 992px) {
  .navbar1 {
    display: none !important;
  }

  .th-sidebar-list-item1 {
    margin-top: 70px;
    justify-content: center;
  }

  .button-mob {
    color: #FFF;
    text-shadow: 0px 3.385px 3.385px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px;
    border-radius: 30px;
    background: linear-gradient(180deg, #03506D 0%, #1A7090 100%);
    box-shadow: 3.385px 3.385px 13.538px 0px rgba(0, 0, 0, 0.15);


    /* CP/White */
    /* 
background: #FCFCFC; */
    /* CP/White */

    border: 2px solid #FCFCFC;
    /* drop-shadow/button-primary */

  }
}

.navbar1 {
  margin-left: 5rem;
}

/*  header ends*/

/* backdrop */
.th-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000080;
  z-index: 950;
}

/* side drawer */
.th-sidedrawer-web,
.th-sidedrawer-mob {
  font-weight: 500;
  height: 100%;


  background: #101010;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 970;
  transition: transform 0.6s ease-out;
}

.th-sidedrawer-web {
  right: 0;

  max-width: 100%;
  transform: translateX(100%);
}

.th-sidedrawer-mob {
  right: 0;
  max-width: 100%;
  transform: translateX(100%);
}

.th-sidedrawer-web.open,
.th-sidedrawer-mob.open {
  transform: translateX(0%);
}

.th-sidebar-list-item {
  text-align: center;
  color: rgba(255, 255, 255, 0.80);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 570;
  line-height: 135%;
  /* 27px */
}

.th-sidebar-list-item:hover {
  background-color: gray;
}

.th-sidebar-list-item1 {
  display: flex;
  padding: 7px 10px;
  border-radius: 24px;

  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.th-sidebar-arrow,
.th-sidebar-icon {
  color: white;
  opacity: 0.87;
}

.th-sidebar-links {
  bottom: 2.5%;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .th-sidebar-list-item {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .th-header {
    width: 100%;
    /* width: 32rem; */
  }

}

.th-sidebar-list-item {
  font-size: 14px;
  margin-bottom: 5px;
}

/* side bar ends */

.active p {
  color: rgb(8, 208, 248);
  font-weight: bold;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: rgb(8, 208, 248);
          text-decoration-color: rgb(8, 208, 248);
  ;

}


.th-register-btn {
  display: inline-flex;
  padding: 10px 23px;
  justify-content: center;
  align-items: center;
  border-radius: 20.308px;
  background: linear-gradient(to right, #03506D 0%, #1A7090 100%);
  box-shadow: 3.385px 3.385px 13.538px 0px rgba(0, 0, 0, 0.15);
}

.th-login-btn {
  width: 110px;
  padding: 7px 16px;
  border-radius: 24px;
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.15);
  background: transparent;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.th-login-btn i {
  width: 26.4px;
  height: 26.871px;
  color: white;
  border: 2.2px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: last baseline;
  font-size: 19px;
}

.th-login-btn span {
  color: #DFEFF5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* @media screen and (min-width:1500px){
    #th-register-btn{
      right:-200px;
    }
    
  }
@media screen and (min-width:1400px){
  #th-register-btn{
    right:-100px;
  }
  
}*/

@media screen and (max-width:900px) {
  .th-new {
    display: none;
  }

  .th-header-new {
    display: none;
  }

  .summit_date {
    display: none;
  }

}
body {
    background: #fafafa;
  }
  .th-registration {
    /* background-position: bottom; */
    /* background-repeat: no-repeat; */
    /* background-size: contain; */
    height: 100vh;
    /* overflow: hidden; */
  }
  .th-login {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    /* overflow: hidden; */
  }
.login{
  width: 100%;
}
  #nsslogin1{
    height: auto;
    width:100%;
  }

  .th-registration-btn,
  .th-registration-btn-2 {
    display: inline-flex;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 8px;
  /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  }
  .th-registration-btn-2:hover{
    cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
  }
  .th-registration-btn {
    padding: 10px 42px;
  }

  .th-registration-btn-2:focus {
    box-shadow: none !important;
  }
  .th-registration-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 36.5px;
    color: #000000;
  }
  .th-registration-text span {
    color: #663aa3 !important;
  }
  .th-register-right-we {
    display: flex;
    flex-direction: column;
    font-family: Raleway;
  }
  .th-register-right-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Raleway;
    flex-basis: 50%;
    
  }
  
  .th-right-text-1 {
    font-size: 33px;
    font-weight: bold;
  }
  .th-right-text-2 {
    font-size: 18px;
    font-family: Inter;
    color: #aaaaaa;
    text-align: left;
    margin-top: 4%;
    margin-left: 2%;
  }
  
  .th-right-link {
    color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }

  #regtxt1{
    display:flex;
  }

#black1{
  color:black;
  padding-right:10px;
}

  #regnow{
    margin-top:16px;
  }

  .logintxthead{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .star1{
    color:#FD652D;
  }

  .rows{
    margin-top: 5%;
    display: flex;
  }

  #forgotpasslog{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .registration-form {
    padding: 3rem;
    text-align: center;
 
  }
  #logintxthead1{
    /* position: absolute; */
    /* width: 54px; */
    /* height: 24px; */

    font-family: 'Lato';
    font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #22495A;
  margin-bottom:2%;
  }
  #regtxthead1{
    color: #000;
font-family: Mulish;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 5%;
  }

#namephone{
  display:flex;
  flex-direction: row;
  margin-top:2%;
}



  #mainlogdiv{
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
 
  }  

#forgotpass{
  margin-top:3%;
} 



.star1{
  text-align:left;
}

#leftregdiv{
  
  width:50%;
} 

#regbtn{
  border-radius: 8px;
background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%);
box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
display: inline-flex;
padding: 8px 22px;
justify-content: center;
align-items: center;
grid-gap: 10px;
gap: 10px;
color: #FFF;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 15%;
height: 6%;
}
#regbtn:hover{
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
}
.rbtn{
  margin: 4%;
  display: flex;
  justify-content: center;
}
#regtxt{
  margin-top:4%;
  display: flex;
  justify-content: center;
  color: #000;
font-family: Mulish;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

  
  /* @media only screen and (max-width: 1000px) {
    #mainlogdiv{
      display: flex;
      flex-direction: column;
    }


  } */
  
  .th-registration-overlay {
    position: absolute;
    left: 100%;
    bottom: 10%;
    transform: translate(-75%, -50%);
    width: 15%;
    z-index: 100;
  }
  
  .th-form-control:focus {
    box-shadow: none !important;
    border: none !important;
  }
  
  .th-form-group {
    display: flex;
    flex-basis: 85%;
    margin: 2.5%;
  }
  .rhide{
    margin-top: 3%;
    width: 6%;
  }
  .rhideimg{
    width: 100%;
  }



  #regimg{
    width:100%;
    
  }

  #rightregdiv{
    width: 50%;
  }

  .th-form-group label {
    /* transition: all 0.3s ease; */
    margin-bottom: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #8e8e93;
  }
  
  .th-form-control {
    /* transition: all 0.3s ease !important; */
    /* background: #f3ebff; */
    border-radius: 2.909px;
background: #D6EBE7;
box-shadow: 0.727px 0.727px 2.909px 0px rgba(0, 0, 0, 0.25);
    width: 85%;
    padding: 2%;
    margin-left: 8%;
    padding-left: 10%;
  }
  .th-form-control1 {
    border-radius: 2.909px;
    background: #D6EBE7;
    box-shadow: 0.727px 0.727px 2.909px 0px rgba(0, 0, 0, 0.25);
  }
  
/* #passwordinput{
  margin-bottom:16px;
  width:61.45%;
} */

.regmaindiv{
  display:flex;
  
  
}

.colmarg{
  flex-basis: 50%;
}
#hidepass{
  display: flex;
  flex-direction: row;
}
  .th-form-control:focus {
    outline: none !important;
    border-color: none !important;
    /* background: #d5bbf8 !important; */
    /* color: #663aa3 !important; */
    box-shadow: none !important;
    border: none !important;
  }

  @media only screen and (min-width: 990px) {
    .regline{
      width:100% !important
    }
  }

  @media only screen and (max-width: 990px) {

    #nsslogin1{
      display:none;
    }

    

    .colmarg{
      padding:0rem;
      margin: auto;
    }

    .registration-form{
      margin:auto;
    }

    .th-registration-text {
      font-size: 26px;
      line-height: 150%;
    }
.th-form-control1 {
display: inline-block;
}
    #rightregdiv{
    margin:auto;
    margin-bottom: 2%;
   }

   #loginlinehead{
    width:61vw;
   }

    #regimg{
      margin:0px;
    }
  
    #leftregdiv{
      height:0px;
      width:0px;
    }

    #namephone{
      flex-direction:column;
    }

    #mainlogdiv{
      display: flex;
      flex-direction: column;
    }

    /* #loginlinehead{
      width:100%;
    } */

    /* #nsslogin1{
      display:none;
    } */

    #regimg{
      display:none;
    }



    #newacctxt{
      display: none;
    }

    #newacctxt1{
      display: none;
    }


    #logcont{
      display:flex;
    }
    
  }
.mainbox{
  display: flex;
  flex-direction: row;
}
.imglogin{
  flex-basis: 50%;
  position: relative;
}
.logimg{
  width: 100%;
  height: auto;
}
.logcontent{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}
.loginhead{
  margin-top: 5%;
}
.loginhead2{
  margin-top: 3%;
}
.userinp{
  position: relative;
  margin-top: 10%;
}
.userimg{
  position: absolute;
  left: 12%;
  top: 18%;
}
.passinp{
  position: relative;
  display: flex;
  margin-top: 4%;
}
.passimg{
  position: absolute;
  left: 12%;
  top: 18%;
}
.hidimg{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5%;
}
.hideimage:hover{
  cursor: pointer;
}
.loghead{
  color: #000;
font-family: Mulish;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.loghead2{
  color: #000;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.logbtn{
  margin-top: 4%;
}
.loginbtn{
  display: inline-flex;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
.loginbtn:hover{
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #8FEFE9;
  box-shadow: 4px 4px 16px 2px rgba(143, 239, 233, 0.50);
}
.forgetpass{
  margin-top: 5%;
}
.forpass{
  color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
input::placeholder{
  
  color: rgba(0, 0, 0, 0.50);
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.account{
  margin-top: 2%;
  color: #000;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.signaccount{
  color: rgba(0, 0, 0, 0.50);
text-align: center;
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media (max-width:990px){
  .imglogin{
    display: none;
    flex-basis: 0%;
  }
  .logcontent{
    flex-basis: 100%;
  }
  .th-form-control{
    width:100%;
    margin-left: 0px;
  }
  .userimg{
    left: 3%;
  }
  .passimg{
    left: 3%;
  }
  .passinp{
    width: 108%;
  }
  #rightregdiv{
    width: 100%;
  }
  #regbtn{
    width: 100px;
  }
  .rhide{
    width: 30px;
    margin-top: 10px;
  }
}
@media (min-width: 200px){
  .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
  }
  }
#sendbtn{
  position: relative;
  left: 15%;
}
@media (min-width: 575px){
.col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}
}

/* 
  @media only screen and (max-width: 776px) {
      .colmarg{
        padding-left: 0rem;
        padding-right: 0rem;
      }
      .th-form-control{
        width:100%;
      }

      #logintxthead1{
        display: flex;
        justify-content: center;
        margin-bottom:2%;
      }
      #forgotpass{
        text-align:left;
        margin-bottom:5%;
        margin-left:0px;
      }
      #loginbtn{
        display:flex;
        align-items: center;
        justify-content: center;
      }

      #mainlogdiv{
        display: flex;
        flex-direction: column;
      }
  
  }
  
  @media only screen and (max-width: 767px) {
    .th-register-col-2-web {
      display: none !important;
    }
  
    .th-registration-btn-1 {
      text-align: center !important;
      display: flex;
      justify-content: center;
    }
    .th-form-control{
      display: inline-block;
    }
  }
  @media only screen and (min-width: 768px) {
    .th-register-col-2-mobile {
      display: none !important;
    }
    .th-form-control{
      display: inline-block;
    }
  
  } */
.header-top {
    position: relative;

}

.header-top {
    position: relative;
}

.header-img-wrapper img {
    /* display: grid;
    grid-template-columns: 25vw 17vw 21vw 13.8vw ;
    grid-template-rows:23vh 2vh 14vh 14vh 25vh ;
    grid-gap: 0px; */
    height: 100vh;
    width: 110vw;
    /* change */
}

.ss-header-img1 {
    height: 25vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}

.ss-header-img2 {
    height: 23vh;
    width: 17vw;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img3 {
    height: 23vh;
    width: 21vw;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img4 {
    height: 23vh;
    width: 14vw;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
}

.ss-header-img5 {
    height: 39vh;
    width: 22vw;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
}

.ss-header-img6 {
    height: 28vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;
}

.ss-header-img7 {
    height: 55vh;
    width: 52vw;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 6;

}

.ss-header-img8 {
    height: 39vh;
    width: 22vw;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 6;
}

.ss-header-img9 {
    height: 25vh;
    width: 25vw;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
}

.logo-heading {
    position: absolute;
    top: 25vh;
    left: 35vw;
    /* border: 3px solid red; */
    height: 200px;
    width: 400px;
    /* display:flex; */
}

.logo-img img {
    height: 170px;
    width: 170px;
    border-radius: 90%;
}



.text .para1 {
    color: #86E7D3;
    font-family: "" Mulish"";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 89%;
    /* 63.36px */
    padding: 10px;

}

.text .para2 {
    color: #DDC38B;
    color: #DDC38B;
    font-family: Mulish;
    font-size: 18.911px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#para3 {
    color: #DDC38B;
    font-size: 20px;
    font-weight: 700;
    font-family: "" Mulish"";
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.h_tickets{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width:1100px) {
    .logo-heading {
        left: 33vw;
    }

    .logo-img img {
        height: 160px;
        width: 160px;
    }

    .text .para1 {
        font-size: 47px;
    }

    .text .para2 {
        font-size: 22px;
    }
}

@media only screen and (max-width:1000px) {
    .logo-heading {
        left: 31.5vw;
    }

    .logo-img img {
        height: 150px;
        width: 150px;
    }

    .text .para1 {
        font-size: 46px;
    }

    .text .para2 {
        font-size: 21px;
    }
}

@media only screen and (max-width:900px) {
    .logo-heading {
        left: 30vw;
    }

    .logo-img img {
        height: 140px;
        width: 140px;
    }

    .text .para1 {
        font-size: 42px;
    }

    .text .para2 {
        font-size: 20px;
    }
}

@media only screen and (max-width:800px) {
    .logo-heading {
        left: 28.5vw;
    }

    .logo-img img {
        height: 130px;
        width: 130px;
    }

    .text .para1 {
        font-size: 40px;
    }

    .text .para2 {
        font-size: 19px;
    }
}

@media only screen and (max-width:700px) {
    .logo-heading {
        left: 25vw;
        width: 300px;
    }

    .logo-img img {
        height: 120px;
        width: 120px;
    }

    .text .para1 {
        font-size: 37px;
    }

    .text .para2 {
        font-size: 18px;
    }
}

@media only screen and (max-width:600px) {
    .logo-heading {
        left: 24vw;
    }

    .logo-img img {
        height: 110px;
        width: 110px;
    }
}

@media only screen and (max-width:570px) {
    .logo-heading {
        left: 22vw;
    }
}

@media only screen and (max-width:550px) {
    .logo-heading {
        left: 21vw;
    }
}

@media only screen and (max-width:530px) {
    .logo-heading {
        left: 20vw;
    }
}

@media only screen and (max-width:500px) {

    .header-top {
        display: none;
    }
}
/* .home-top-content-wrapper{
    margin:0px;
    padding: 0px;
} */

.home-top-content-wrapper{
    
}
.header-bottom {
    /* background-image: url("../Image/image\ 38.png"); */
    /* position: relative; */
    height: auto;
    text-align: center;
    padding: 14vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    /* margin-top: 0px;
    margin: 0; */

}
.backimg-head{
    position: absolute;
    /* left: 0px;
    top: 0px; */
    z-index: -10;
    width:110vw;
    height: 126vh;
    padding: 10px 0;

    /* filter: brightness(50%); */
  }
.header-bottom-heading {
    font-family: ""Mulish"";
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 99%;
    /* 63.36px */
    background: linear-gradient(to bottom, #652222 40%, #CB4545 100%);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.header-bottom-para1 {
    text-align: left;
    font-family: ""Mulish"";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 70%;
    /* 43.2px */
    /* background: linear-gradient(184deg, #FFF 3.29%, #5DC1C2 176.52%); */
    background-color: #43766C;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 30px 0px;
    /* display: flex;
    flex-direction: column; */
}
.header-bottom-line2{
    font-size: 25px;
}
.header-bottom-para3 {
    color: #652222;
    text-align: left;
    font-family: ""Mulish"";
    font-size: 20px;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #C1A76F;
    border-radius: 30px;
    padding: 2rem;
    width: 73vw;
    background-color: rgba(193, 167, 111, 0.6)
}

.header-bottom-button {
    /* background-color: black; */
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    /* height: 200vh; */
}

.header-bottom-button button {
    display: inline-flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #5AB9BE;
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: transparent;

}

#btn-1 {
    margin-right: 20px;
    border-radius: 5rem;
    /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
    background: linear-gradient(90deg, #00d084 0%, #008758 100%);
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
}
#btn-1:hover{
    border: 2px solid #8FEFE9;
    box-shadow: 4px 4px 16px 2px rgba(153, 238, 232, 0.5);
}
.home-logo-display{
    font-weight: 600px;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    display:none;
}
.nss{
    padding-top: 2rem;
}

@media only screen and (max-width:900px){
    .home-logo-display{
        display: flex;
    }
    /* .header-bottom-para3{
        width: 40rem;
    } */

    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:1230px){
    .backimg-head{
        height: 163vh;
    }
}
/* @media only screen and (max-width:1020){
    .backimg-head{
        height: 200vh;
    }
} */

@media only screen and (max-width:1090px){
    .header-bottom-heading{
        font-size: 55px;
    }
    /* .header-bottom-para3{
        width: 50rem;
    } */
    .backimg-head{
        height: 163vh;
    }
}
@media only screen and (max-width:1020){
    /* .backimg-head{
        height: 190vh;
    } */
    .home-top-content-wrapper{
        height: 59rem;
    }
}
@media only screen and (max-width:930px){
    .header-bottom-heading{
        font-size: 50px;
    }
    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:940px){
    .header-bottom-heading{
        font-size: 45px;
        
    }
    .backimg-head{
        height: 200vh;
    }
}
@media only screen and (max-width:820px){
    .home-top-content-wrapper{
        height: 67rem;
    }
}
@media only screen and (max-width:760px){
    .header-bottom{
        padding: 5vh 12vw;
    }
    .backimg-head{
        height: 204vh;
    }
}



@media only screen and (max-width:720px){
    .header-bottom-heading{
        font-size: 40px;
    }
    .backimg-head{
        height: 230vh;
    }
}
@media only screen and (max-width:700px){
    .home-top-content-wrapper{
        height: 63rem;
    }
}
@media only screen and (max-width:630px){
    .home-top-content-wrapper{
        height: 55rem;
    }
}


@media only screen and (max-width:640px){
    .home-top-content-wrapper{
        height: 55rem;
    }
    .header-bottom-heading{
        font-size: 35px;
    }
    .header-bottom-para1{
        font-size: 22px;
    }
    .header-bottom-line2{
        font-size:17px;
    }
    .header-bottom-para3{
        font-size: 17px;
    }
    .backimg-head{
        height: 197vh;
    }
}
@media only screen and (max-width:560px){
    
    .home-top-content-wrapper{
        height: 50rem;
    }
    .backimg-head{
        height: 178vh;
    }
    .header-bottom-heading{
        font-size: 30px;
    }
    .header-bottom-line2{
        font-size: 18px;
    }
    .header-bottom-para3{
        font-size:14px;
        width: 24rem;
        
    }
    .header-bottom-para3{
        font-size: 16px;
        margin-top: 5vh;
    }
    .header-bottom-para1{
display: none;
    }
}

@media only screen and (max-width:400px){
    .backimg-head{
        /* width:  32rem; */
        width: 100%;
    }
    .header-bottom{
        display: flex;
        flex-direction: column;
        /* padding-left: 7rem; */
        /* width: 32rem; */
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    /* html{
        display: flex;
        justify-content: center;
    } */
    .home-logo-display{
        /* display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center; */
        /* margin-left: 20px; */
        /* width: 32rem; */
        width: 100%;
    }
    .header-bottom-button{
       /* margin: 0 auto; */
        padding-right: 105px;
        
    }
    .header-bottom-para3{
        width: 90vw;
    }
    #btn-1{
        margin: 0;
        scale: 0.8;
        align-items: center;
    }
    
}


body{
    background: #080808;
z-index: -10000;
}
.Contact-container {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;
    align-content: space-evenly;
    background: #080808;
    /* padding: 5vh 2vw 5vh 2vw; */
    
}

.footerCol1 {
    display: flex;
    flex-direction: column;
    width: 24vw;
    height: 40vh;
    align-items: center;
    padding: 3%;
    grid-gap: 50px;
    gap: 50px;
}
.footerLogo {
    display: flex;
    flex-direction: row;
    width: 94%;
    height: 20%;
    padding-right: 8%;
    grid-gap: 1%;
    gap: 1%;
}

.logoImg {
    width: 40%;
    height: 100%;
   
}
.logoContaint {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    width: 50%;
    height: 100%;
    
}
.logo2{
    width: 80%;
}

.NSS {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 19.381px;
    font-style: normal;
    font-weight: 600;
    line-height: 99%;
    height: 90%;
    
}

.date-box {
    font-family: ""Mulish"";
    font-size: 7.268px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 10%;
}


.footerText {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 94%;
    height: 35%;
}

.contactusBtn {
    font-family: ""Mulish""; 
    font-size: 16px;
    font-style: normal;
    margin-top: 2vh;
    font-weight: 700;
    line-height: normal;
    width: 94%;
    height: 15%;
    
}
.btn-footer {
    /* margin-right: 20px; */
    border-radius: 20px;
    color: #FFF;

    /* background: linear-gradient(94deg, #028BA3 22.96%, #006E9B 98.15%); */
    background: linear-gradient(95.72deg, #10D7B0 2.01%, #08856C 97.01%);

    padding:8px 10px;
    box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.25);
}
.btn-footer:hover{
    border: 2px solid #72e0d7;
    box-shadow: 4px 4px 16px 2px rgba(153, 238, 232, 0.5);
}

.footerText {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 94%;
    height: 35%;
}

.contactusBtn {
    color: #FFF;
    font-family: ""Mulish"";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 94%;
    height: 15%;
}
.footerCol2 {
    display: flex;
    flex-direction: row;
    color: #FFF;
    text-decoration: none;
    width: 35vw;
    height: 50vh;
    grid-gap: 8rem;
    gap: 8rem;
    
    padding: 6vh 6vw 6vh 6vw;
    margin-top: 4rem;
}
.col1 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-left: 4vw; */
    /* text-align: center; */
    grid-gap: 10px;
    gap: 10px;
    color: #FFF;
    text-decoration: none;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 23vw;
    height: 38vh;
}

.col1 a{
    text-decoration: none;
    color: #FFF;
}
.col-conn {
    display: inline-flex;
    flex-direction: column;
    /* align-items: flex-start; */
    margin-left: 1vw;
    text-align: center;
    margin-top: 2vh;
    grid-gap: 16px;
    gap: 16px;
    color: #FFF;
    text-decoration: none;
    font-family: ""Mulish"";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 23vw;
    height: 38vh;
}
.col-conn a{
    text-decoration: none;
    color: #FFF;
}
.footerCol3 {
    display: flex;
    flex-direction: row;
    width: 30vw;
    height: 50vh;
    align-items: center;
    justify-content: center;
    padding: 10vh 3vw 10vh 3vw;
    margin-left: 3vw;
    color: #FFF;
}
.icon-box{
width:22vw;
height:20;
grid-gap: 10%;
gap: 10%;
margin-top: 3rem;
margin-left: 5rem;
display: flex;
 align-items: center;
    justify-content: center;
}
.icon{
    width: 10%;
    height: 10%;
    
}
.fabIcon{
    color: #E7DDB2;
}


.col1 a{
    color: #E7DDB2;
}
.col1 h4{
    color: #86E7D3;

}

.footerText p{
    color: #E7DDB2;
}
.logoContaint p{
    color: #86E7D3;
}
.date-box p{
    color: #E7DDB2;
}
body {
    background: #080808;
    z-index: -10000;
}

.mob-foot-Contact {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    /* padding: 2.5vh 2vw 2.5vh 2vw; */
    background: #080808;
    align-items: center;
    color: #FFF;
    text-decoration: none;
    grid-gap: 2%;
    gap: 2%;
}

.mob-foot-date-box {
    color: #E7DDB2;

}

.mob-foot-footerCol1 {
    width: 100%;
    height: 20%;
    padding: 3%;
    grid-gap: 50px;
    gap: 50px;
    display: flex;
    color: #FFF;
    text-decoration: none;
}

.mob-foot-footerCol2 {
    width: 100%;
    height: 40%;
    grid-gap: 10px;
    gap: 10px;
    color: #FFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.mob-foot-footerCol3 {
    width: 100%;
    height: 10%;
    padding: 0 3% 5% 3%;
}

.mob-foot-contactusBtn {
    width: 100%;
    height: 20%;
    padding: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: black;
}

.contact {
    background: linear-gradient(95.72deg, #10D7B0 2.01%, #08856C 97.01%);
    border: black;
}

.contact:hover {
    border: #10D7B0;
}

.mob-foot-footerText {
    width: 54%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5vh;
    color: #E7DDB2;

}

.mob-foot-footerLogo {
    width: 40%;
    height: 100%;
    display: flex;
    color: #FFF;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mob-foot-logo {
    height: 15vh;
    width: 15vw;
}

.mob-foot-logoContaint {
    margin-top: 2vh;
    width: 25vw;
    color: #86E7D3;


}

.mob-foot-col1 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    color: #86E7D3;
    text-decoration: none;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mob-foot-col1 a {
    text-decoration: none;
    color: #E7DDB2;
}

.mob-foot-icon-box {
    width: 94%;
    height: 95%;
    grid-gap: 8%;
    gap: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mob-foot-icon {
    width: 10%;
    height: 95%;
}

.mob-foot-fabIcon {
    width: 100%;
    height: 100%;
}


@media (max-width:500px) {

    .mob-foot-col1 {
        grid-gap: 10px;
        gap: 10px;
        font-size: 10px;
        font-weight: 300;
    }
}


body {
    background: #fafafa;
  }
.th-comingsoon {
    background-image: url(/static/media/bg-signup.8503d2c8.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    overflow: hidden;
  }

  .th-register-right-web {
    display: flex;
    flex-direction: column;
    /* margin-top: 6rem; */
    justify-content: center;
    text-align: center;
    font-family: Raleway;
  }
  .th-right-text-1 {
    font-size: 33px;
    font-weight: bold;
  }
  .th-right-text-2 {
    font-size: 18px;
    font-family: Inter;
    color: #aaaaaa;
  }
  
  .th-register-right-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Raleway;
  }
.ss-comingsoon-subheading{
    color: #663AA3;
    font-weight: 550;
    font-size: 90px
    ;
}
#button-addon{
    background: #663AA3;
    color: azure
    ;
}
#emailaddress{
    background: #F3EBFF;

    color: azure
    ;
} 


@media only screen and (max-width: 576px) {

  }
  
  @media only screen and (max-width: 767px) {
    .th-register-col-2-web {
      display: none !important;
    }
  
    .th-registration-btn-1 {
      text-align: center !important;
    }
  }
  @media only screen and (min-width: 768px) {
    .th-register-col-2-mobile {
      display: none !important;
    }
  }
.profile-header{
    height : 15%;
    width : 100%;
    
}

.profile-header-container{
    border-bottom : 0.1rem solid orangered !important;   
}

.profile-component{
    height: 85%;
}


.single-detail{
    margin : 0.75rem;
    margin-bottom :1rem; 
}
.events-header{
    height: 9%;
    width : 100%;
    margin-bottom : 1rem
}



.events-component{
    height: 85%;
}


.choose-event-text{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1rem;
  display: flex;
  align-items: center;

  color: #444444;
  margin : 0.5rem 0 0.5rem 0
}


.checkbox-container{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;

    color: #444444;
}

.checkbox-container input{
    margin : 0.5rem
}

.choose-event-not-registered{
    background: orangered !important;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);

}

/*.choose-event-successfully-registered{
    background: #368a67;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
}
*/

.successfully-registered-class{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    color : #19ac56;
}

.choose-event-container{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    height: 100%
}
.single-participant-form{
    box-shadow: 2px 2px 4px rgba(102, 58, 163, 0.25);
    border-radius: 8px;
    padding : 0.5rem
}

.input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.input-form-field{
    
    margin : 1rem
}

.submit-button-row button{
    background: orangered;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
}

.my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}
.single-member-component-container{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    align-items: center;
    padding : 0.6rem;

    color: #663AA3;

    background: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
    border-radius: 4px;
    margin : 0.5rem 0 0.5rem 0
}
.add-member-component-container{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    align-items: center;
    padding : 0.6rem;

    color: orangered;

    border: 1px solid orangered;
    box-sizing: border-box;
    border-radius: 4px;
    margin : 0.5rem 0 0.5rem 0
}




.participant-header{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;

    color: #444444; 
    margin : 0rem 0 0.5rem 0;

}
.input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.input-form-field{
    margin : 1rem
}

.my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}
.CardsWrapper {
  margin: 1em;
}

.event-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #444444;
}

.event-date {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.4rem;
  /* identical to box height */

  color: #666666;
}

.event-resources {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3rem;
  /* identical to box height */

  -webkit-text-decoration-line: underline;

          text-decoration-line: underline;

  color: #663aa3;
}
.event-unregister {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3rem;
  /* identical to box height */

  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  color: #ff5959;
}
.EventCards {
  display: grid;
  grid-template-columns:  23% 23% 23% 23%;
  grid-column-gap: 2%;
  column-gap: 2%;
  grid-row-gap: 4%;
  row-gap: 4%;
}

.EventType {
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
  border-radius: 2.93684px;
  padding: 1.5rem;
 
}

.event-links {
  display: flex;
  justify-content: space-between;
}


.mobile-CardsWrapper {
    margin: 1rem 0 1rem 0;
  }
  
  .mobile-event-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: #444444;
  }
  
  .mobile-event-date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.4rem;
    /* identical to box height */
  
    color: #666666;
  }
  
  .mobile-event-resources {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.3rem;
    /* identical to box height */
  
    -webkit-text-decoration-line: underline;
  
            text-decoration-line: underline;
  
    color: #663aa3;
  }
  .mobile-event-unregister {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.3rem;
    /* identical to box height */
  
    text-align: right;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  
    color: #ff5959;
  }
  .mobile-EventCards {
    display: grid;
    grid-template-columns:  100%;
    grid-column-gap: 2%;
    column-gap: 2%;
    grid-row-gap: 4%;
    row-gap: 4%;
  }
  
  .mobile-EventType {
    background: #ffffff;
    box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
    border-radius: 2.93684px;
    padding: 1.5rem;
   
  }
  
  .mobile-event-links {
    display: flex;
    justify-content: space-between;
  }
  
.mobile-choose-event-text{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
  
    color: #444444;
    margin : 0.5rem 0 0.5rem 0
  }
  
  
.mobile-checkbox-container{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;

    color: #444444;
}

.mobile-checkbox-container input{
    margin : 0.5rem
}

.mobile-choose-event-register-button{
    background: #663AA3;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);

}

.mobile-choose-event-container{
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    height: 100%
}

.choose-event-not-registered{
    background: #663AA3;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);

}

.successfully-registered-class{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    color : #19ac56;
}
.mobile-single-participant-form{
    box-shadow: 2px 2px 4px rgba(102, 58, 163, 0.25);
    border-radius: 8px;
    padding : 0.5rem;
    margin: 1rem 0 2rem 0;
}

.mobile-input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.mobile-input-form-field{
    
    margin : 1rem
}

.mobile-submit-button-row button{
    background: #663AA3;
    padding-left : 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius : 8px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #FFFFFF;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
}

.mobile-my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}
.mobile-single-member-component-container{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    align-items: center;
    padding : 0.6rem;

    color: #663AA3;

    background: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(102, 58, 163, 0.25);
    border-radius: 4px;
    margin : 0.5rem 0 0.5rem 0
}
.mobile-add-member-component-container{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    align-items: center;
    padding : 0.6rem;

    color: #663AA3;

    border: 1px solid #663AA3;
    box-sizing: border-box;
    border-radius: 4px;
    margin : 0.5rem 0 0.5rem 0
}



.mobile-participant-header{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;

    color: #444444; 
    margin : 0rem 0 0.5rem 0;

}
.mobile-input-form-field label{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    display: flex;
    align-items: center;
    margin : 0.5rem 0 0.5rem 0;
    color: #444444;

}

.mobile-input-form-field{
    margin : 1rem
}

.mobile-my-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #212529;
}
.tabs-container{
    height : 100%,
    
}

.selected-tab{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1rem;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: orangered;
    border-bottom: 0.1rem solid orangered;
    padding-left: 2.5em;
    padding-bottom: 0.9em;
}

.not-selected-tab{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #666666;
}
.tabs{
    height : 7%
}

.tab-main-view{
    height : 80%;
   
}

.mobile-tab-main-view{
    height : 90%;
    margin-top : 3rem
}

.event-submission-form-text{
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.2rem;
    text-align: center;
    color: #444444;
}

.event-submission-form-button a{
    padding : 1rem;
    background: orangered;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    color : #ffffff;
    margin : 0 2rem 0 2rem
}

.updates-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.updates-component{
    height: 85%;
}

.timeline-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.timeline-component{
    height: 85%;
}

.payment-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.payment-component{
    height: 85%;
}


.payment-button a{
    background: orangered;
    box-shadow: inset -1px -1px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 4px rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    padding : 1rem;
    margin : 1rem 0 1rem 0;
    color : #ffffff
}
.main-component-container{
    height : 100%;
    width : 100%;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(253, 101, 45, 0.25);
    /* filter: drop-shadow(1px 1px 4px,rgba(253, 101, 45, 0.25)); */
    border-radius: 10px;
    padding : 1rem;
}

.main-component-head{
    height : 10%;

}




.points{
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: right;

    color: #663AA3;

}

.current-points{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.75rem;
    color: #444444;
    text-align: right;
}

.profile-header{
    height : 15%;
    width : 100%
}

.profile-header-container{
    border-bottom : 0.1rem solid #8561B5;
    
}

.profile-component{
    height: 85%;
}


.single-detail{
    margin : 0.75rem;
    margin-bottom :1rem; 
}
.th-home-dashboard{
    border : solid #f6f6f6 0.2rem;
    
    border-radius:10px;
    overflow-x: hidden;
  }
  
  .th-home-dashboard-header{
    display:flex;
    background: #FAFAFA;
    justify-content: space-between;
    padding: 5px 3.13rem;
    box-shadow: 0px 4px 8px rgba(102, 58, 163, 0.25);
    border-radius: 10px 10px 0 0;
  }
  
  
  .th-home-dashboard-card{
    display:flex;
    justify-content: space-between;
    margin: 0 50px 0 30px;
  
  }
  
  .line{
    height:1px;
    margin:0 1rem;
    background-color: #663AA3;
    
    opacity: 0.3;
  }
  
  
  .th-home-dashboard-header div{
    font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  
  }
  
  
  .th-home-dashboard-card div{
    font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1rem;
  
  color: #444444;
  
  }
  
  
  .th-home-dashboard-card{
    padding:10px 0;
  }
  
  
  .th-home-dashboard-body{
    height : 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .dot{
    width: 8px;
    height:8px;
    border-radius: 50%;
    background-color: #663AA3;
    display:flex;
    justify-items:center;
    align-self: center;
    margin-right:10px;
  }
  
  .th-home-dashboard-cardname{
    display:flex;
  }

  .th-home-dashboard-body::-webkit-scrollbar {
    width: 0.2rem;
  }

  .th-home-dashboard-body::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

  .th-home-dashboard-body::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }
.mytasks-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.mytasks-component{
    height: 85%;
}

.my-tasks-details-component{
    border-right : solid 0.1rem #663AA3
}

.my-tasks-details-component{
    position : relative
}


.single-detail{
    margin : 0.75rem;
    margin-bottom :1rem; 
}
.list-component-div{
    position : absolute;
    top : 2rem;
    z-index : 1000;
    width : 100%;
    height : 15rem;
    overflow-y: scroll;
}

.list-component-div::-webkit-scrollbar {
    width: 0.2rem;
  }

  .list-component-div::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

  .list-component-div::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }


.select-tasks-dropdown{
    background: rgba(142, 142, 147, 0.12);
    border-radius: 5px;
}

.select-tasks-header{
    color : #444444;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    margin : 0.4rem;
    display: flex;
    align-items: center;
}

.select-tasks-dropdown{
    width : 60%;
    background: rgba(142, 142, 147, 0.12);
    border-radius: 5px;
    padding : 0.4rem;
    margin : 0.4rem
}

.select-task-name{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    /* identical to box height */
    padding:0;
    display: flex;
    align-items: center;

    color: #666666;

}

.select-tasks-dropdown{
    position :relative
}

.dropdown-list{
    position: absolute;
    top : 2.5rem;
    z-index : 1000
}

.select-task-list-component{
    padding-top: 0.3rem;
}

.select-tasks-single-component{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1rem;
    /* identical to box height */
    padding:0;
    display: flex;
    align-items: center;

    color: #666666;
}

.select-tasks-single-component:hover {
    background: rgba(142, 142, 147, 0.3);;
  }


.th-home-CurrentTask{
  display:grid;
  grid-template-columns: 0.2fr 3fr 2fr;
  margin:1rem;
  font-family: Inter;
font-style: normal;
font-weight: normal;
font-size:0.9rem;
line-height: 1rem;



color: #444444;
}

.th-home-CurrentTaskbtn{

  width:100px;
 
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  justify-self: end;
}


.inputfile + label {
  font-family: Inter;
  justify-self: end;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
  width: 110px;
  color:#663AA3;
  
  border: 1px solid #663AA3;

border-radius: 5px;
  cursor: pointer;
  padding:5px;
  justify-content: end;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: lightgray;
}

.Submitbtn{
  margin:0 50px;
  width:80%;
  background: #F0EBF6;
  border-radius: 5px;
  color: #888888;
  cursor: pointer;
  height: 32px;
  border:none;
  font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 1.1rem;
}

.errButton{
  margin:0 50px;
  width:80%;
  background: #F0EBF6;
  border-radius: 5px;
  background-color: #b83535;
  cursor: pointer;
  height: 32px;
  border:none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1rem;
  color:#ffffff;
}

.uploadedBtn{
  color:#ffffff;
  margin:0 50px;
  width:80%;
  background: #F0EBF6;
  border-radius: 5px;
  background-color: #3c9468;
  cursor: pointer;
  height: 32px;
  border:none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1rem;
}
.leaderboard-header{
    height : 10%;
    width : 100%;
    margin-bottom : 1rem
}



.leaderboard-component{
    height: 85%;
}

.rankerCard {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0;
  justify-content: space-between;

}

.profile{
  margin-right : 0.5rem;
}


.rankerName {
  width: 65%;
}

.points {
  width: 20%;
}

.rankerName h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
 
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #444444;
  margin: 0;
}

.rankerName h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 0.9rem;
  /* identical to box height */
  color: #663aa3;
  margin: 0;
}

.points {
  margin-left: auto;
}

.points h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  /* identical to box height */
  text-align: right;
  color: #444444;
  margin: 0;
}

.verticalLine {
  position: absolute;
  height: 58%;
  left: 51%;
  opacity: 0.3;
  border-radius: 16px;
  border: 4px solid #663aa3;
}
.leaderboard h1 {
  width: 90%;
  margin: auto;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.3rem;
  color: #222222;
}

.rankers-2{
    column-count: 2;
    grid-row-gap: 0.1rem;
    row-gap: 0.1rem;
    grid-column-gap: 10rem;
    column-gap: 10rem;
    column-rule: 4px solid #663aa36c;
}

.rankers-1{
  column-count: 1;
  grid-row-gap: 0.1rem;
  row-gap: 0.1rem;
  grid-column-gap: 10rem;
  column-gap: 10rem;
  column-rule: 4px solid #663aa36c;
}



.ranker {
    width: 100%;
  }
.deadlines-header{
    height : 7%;
    width : 100%;
    margin-bottom : 1rem
}



.deadlines-component{
    height: 85%;
    overflow-y: scroll;
}

.deadlines-component::-webkit-scrollbar {
    width: 0.1rem;
  }

.deadlines-component::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

.deadlines-component::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }

.itemCard {
  display: flex;
  
  justify-content: space-between;
  padding: 0.6rem 0;
}

.dateBlock {
  display: inline-flex;
  padding: 0.2rem;
  flex-direction: column;
  justify-content: center;
  width: 12%;
  align-items: center;
  /* height: 2px; */
  background: #f5e0d1;
  box-shadow: inset -1px -1px 4px #ffe8d7,
    inset 1px 1px 4px rgb(158 143 132 / 50%);
  border-radius: 5px;
}

.dateBlock h1 {
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #663aa3;
  margin: 0;
}
.dateBlock h2 {
  display: inline-block;
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1rem;
  text-align: center;
  color: #663aa3;
}

.tasks {
  display: flex;
  flex-direction: column;
  align-self: center;
  /* j-content: space-between; */
  /* margin-left: 2vw; */
  width: 83%;
}

.tasks h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.2rem;
  /* identical to box height */
  color: #444444;
}

.tasks p {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1rem;
  /* identical to box height */
  color: #aaaaaa;
}


.task_heading {
  /* display: inline-block; */

  margin: auto;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 2.9rem;
  line-height: 3.3rem;
  color: #222222;
}

.load {
  display: flex;
 
  margin: auto;
  justify-content: flex-end;
  align-items: center;
}

.load img {
  /* float: right; */
  order: 1;
}

.loadMore {
  /* width: 90%; */
  /* margin: 1rem auto; */
  /* float: right; */
  margin: 0 0.6rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1rem;
  /* identical to box height */
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #663aa3;
}

.verticalLine1 {
  position: absolute;
  left: 24%;
  height: 27rem;
  opacity: 0.5;
  border: 0.5px solid #663aa3;
}
.verticalLine2 {
  position: absolute;
  left: 49%;
  height: 27rem;
  opacity: 0.5;
  border: 0.5px solid #663aa3;
}

.with-border{
  border-right : 0.5px solid #663aa3;
}
.updates-header{
    height : 15%;
    width : 100%;
    margin-bottom : 1rem
}



.updates-component{
    height: 85%;
}

.wrapper {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.75),
      2px 2px 4px rgba(102, 58, 163, 0.25);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */
  
    border-radius: 10px;
  }
  
  .heading {
    height: 20%;
    width: 90%;
    margin: auto;
    padding-top: 0.5rem;
  }
  
  .indexupdates {
    height: 80%;
    /* overflow-y: hidden; */
  }
  .update-list-component {
    overflow-y: scroll;
    height: 50vh;
  }
  
  .component {
    padding-bottom: 2rem;
    width: 80%;
    margin: 0.1rem;
  }
  
  .heading {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
    padding-bottom: 1rem;
    color: #222222;
  }
  .date {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #666666;
  }
  
  .line {
    border: 1px solid #888888;
    width: 100%;
  }
  
  .updates {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
  
  .profile {
    display: flex;
  }
  
  .profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .time {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    padding-right: 1.5rem;
    color: #aaaaaa;
  }
  
  .profilePhoto {
    padding: 0.2rem;
  }
  
  .taskPerson {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1rem;
  
    color: #222222;
    margin:0.2rem
  }
  
  .taskInfo {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.0rem;
  
    color: #666666;
    margin:0.2rem
  }

  .update-list-component::-webkit-scrollbar {
    width: 0.2rem;
  }

  .update-list-component::-webkit-scrollbar-track {
    background : #E5E5E5;
    border-radius: 8px;
  }

  .update-list-component::-webkit-scrollbar-thumb {
    background: #8561B5;
    border-radius: 8px;
  }
.navbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.75),
    2px 2px 4px rgba(102, 58, 163, 0.25); */
    /* 2px 2px 4px rgba(253, 101, 45, 0.25) */
  box-shadow: 2px 2px 4px rgba(253, 101, 45, 0.25);
  border-radius: 10px;
  padding: 1rem;
  padding-bottom: 8rem;
  padding-right :0;
  margin-bottom: 2rem;
}

.navItems {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.navItems img {
  width: 10%;
}
.navItems h1 {
  width: 70%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  color: #444444;
  margin-left: 1rem;
  margin-bottom: 0;
}

.isSelected{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 2.5rem;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;

    color:orangered;

}

.isNotSelected{
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 2.5rem;

color: #444444;
}
@media screen and (max-width: 760px) {
    #dh{
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 37px;
      line-height: 37px;
    }
  }
@media screen and (min-width: 760px) {
    #dh{
        font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 69.9568px;
    line-height: 82px;
    }
  }

  .user-container {
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(102, 58, 163, 0.25);
    border-radius: 10px;
    padding : 1.5rem
  }

  .mobile-user-container{
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(102, 58, 163, 0.25);
    border-radius: 10px;
    padding : 0.1rem
  }

  .user-name{
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #222222;
  }

  .user-role{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #AAAAAA;
  }

  .link a{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1rem;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;

    color: orangered;

  }

  .link {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1rem;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;

    color: orangered;

  }
  
  .modal {
    padding-top: 4rem;
    padding-right: 1rem;
    padding-left : 1rem;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: start;
    justify-content: end;
   
  }

  .modal-content {
    
    background-color: #fff;
   
  }
  
.dashboard-text{
    font-family: Raleway;
    font-style: normal;
    font-weight: 900;
    font-size: 3.5rem;
    line-height: 3.6rem;
    color: #222222;

}

.ca-id-text{
    margin-top : 0.2rem;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.3rem;
    color: #AAAAAA;

}

.mobile-dashboard-text{
    font-family: Raleway;
    font-style: normal;
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 1.8rem;
    color: #222222;
}

.mobile-ca-id-text{
    margin-top : 0.2rem;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #AAAAAA;
}
.body-container{
    background-image:url(/static/media/bg-signup.8503d2c8.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size:100% 45%;
    background-attachment: fixed;
    width : 100%;
    height : 100%;
    padding: 0rem;
  }
  
  

.mobile-single-component-container{
    border : 0.1rem solid #ff5349;
    border-radius: 18.5px;
    height : 6.5vh;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1rem;
    text-align: center;
    margin : 0rem;
    
}

.slide-me{
  
  display:flex;
  flex-direction: row;
  overflow-x: scroll;
}

.card-component{
    margin-left: 0.1rem;
    margin-right : 0.1rem;
    margin-top: 0.1rem;
}
.Admincontainer{
    margin-top: 10%;
width: 100vw;
margin-left: 10vw;
height: 60vh;
}
.adminbtns{
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 13vw;
    margin-top: 10px;
}
.adminbtns:hover {
    background-color: #0069d9;
  }
  
  .adminbtns:active {
    background-color: #005cbf;
  }
  .adminbuttons{
    display: flex;
    flex-direction: column;
  }
body{
    background-color: rgb(222, 196, 140);
}
